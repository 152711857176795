const getVariantData = (vwoClientInstance, experimentList) => {
    const variantMap = {};
    const experiments = Object.keys(experimentList);

    if (vwoClientInstance.activate) {
        experiments.forEach(experiment => {
            const variant = vwoClientInstance.activate(experimentList[experiment]);

            variantMap[experimentList[experiment]] = variant;
        });
    }
    return { variant: variantMap };
};

export { getVariantData };
